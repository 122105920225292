<script>
import moment from "moment";

export default {
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      styles: {
        online: 'success',
        offline: 'danger',
        manually: 'warning',
      }
    };
  },
  computed: {
    statusText() {
      return this.profile.performer ?
        (this.profile.performer.data.online ? 'online' :
          (this.profile.force_online && moment.utc(this.profile.force_online) > moment()) ? 'manually' : 'offline') :
        '-';
    },
    statusStyle() {
      return this.styles[this.statusText];
    }
  },
};
</script>

<script>

import {mapGetters} from "vuex";
import hmacsha1 from "hmacsha1";

export default {
  computed: {
    ...mapGetters('Profile', ['getType']),
  },
  methods: {
    profilePlatformId(profile) {
      return profile.platform_id ?? profile.performer.data.platform_id;
    },
    profileName(profile) {
      return this.getType(profile) === 'link' ? profile.title : profile.performer.data.name
    },
    editProfile(profile) {
      this.$router.push({
        name: profile.type === 2 ? "edit_link" : "edit_profile",
        params: { mounted_profile: profile, profileId: profile.id }
      });
    },
    delete(profile) {
      this.$swal.fire({
        title: this.getTrans("messages.are_you_sure"),
        text: this.getTrans("messages.delete_profile_text", profile.name),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.getTrans("messages.yes"),
        cancelButtonText: this.getTrans("messages.cancel"),
        buttonsStyling: false
      }).then(result => {
        if (result.value)
          return this.$store.dispatch("Profile/delete", profile.id).then(() => {
            this.$toast.success(this.getTrans("messages.profile_deleted"));
          });
      });
    },
    boost(profile, link=false) {
      this.$router.push({
        name: "boost",
          params: { mounted_profile: profile, link}
      });
    },
    showStats(profile) {
      this.$router.push({
        name: "ProfileStats",
        params: { mounted_profile: profile }
      });
    },
    profile_img(profile) {
      if (profile.type==2) { //Link
        return "../../../img/diva_avatar.png";
      }
      if (profile.profilePicUrl != "") {
        return profile.profilePicUrl;
      } else if (
        (profile.performer.data.avatar_url !== "" ||
          profile.performer.data.screenshot_url !== "") &&
        (profile.performer.data.avatar_url !== null ||
          profile.performer.data.screenshot_url !== null)
      ) {
        let url = profile.performer.data.avatar_url
          ? profile.performer.data.avatar_url
          : profile.performer.data.screenshot_url
          ? profile.performer.data.screenshot_url
          : "";
        let image_url = encodeURIComponent(
          url.replace(/^http:\/\/|^\/\/|^https:\/\/|^\/\//i, "")
        );
        let hash = hmacsha1(process.env.VUE_APP_THUMBOR_SECRET, image_url)
          .replace(/\//g, "_")
          .replace(/\+/g, "-");
        return process.env.VUE_APP_THUMBOR_SERVER + hash + "/" + image_url;
      } else {
        return "../../../img/diva_avatar.png";
      }
    },
    errorAvatar(event) {
      event.target.src = "/img/diva_avatar.png";
    },

    changeShowInAds(profile) {
      let p = {
        id: profile.id
      };
      p.show_in_ads = this.showInAds ? "1" : "0";
      this.$store.dispatch("Profile/save", p);
    }
  },
};
</script>

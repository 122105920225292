<template>
  <div class="profile-thumbnail">
    <div v-for="(profile) in displayProfiles" :key="profile.id" class="profile-thumbnail__img-group my-auto">
      <ProfileAvatar :profile="profile" :show-tooltip="showTooltip"></ProfileAvatar>
      <el-tooltip placement="bottom" transition="" v-if="showDelete" :content="profileName(profile)">
        <span class="profile-thumbnail__img-group--delete" @click.stop="removeProfile(profile)">
          <i class="fas fa-times"></i>
        </span>
      </el-tooltip>
    </div>
    <div class="profile-thumbnail__img-group" v-if="extra">
      <div class="profile-thumbnail__img-wrapper extra">+{{extra}}</div>
    </div>
    <div v-if="displayProfiles.length==1" class="profile-thumbnail__info my-auto">
        <p class="profile-thumbnail__info--name text-left">{{profileName(displayProfiles[0])}}</p>
        <div class="profile-card__platform-logo-container">
          <img v-if="getType(displayProfiles[0])!='link'" class="profile-card__platform-logo" :src="platformLogo(profilePlatformId(displayProfiles[0]))"/>
          <a v-else :href="getProfileLandingUrl(displayProfiles[0])" target="_blank" class="profile-card__platform-logo-container-url text-left m-0" :title="displayProfiles[0].url">{{limitText(displayProfiles[0].url, 40)}}</a>
        </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ProfileAvatar from "@/views/Profiles/Components/ProfileAvatar";
import ProfileMixin from "@/mixins/Profile/ProfileMixin";
import PlatformMixin from "@/mixins/PlatformMixin";

export default {
  components:{
    ProfileAvatar
  },
  name: "profiles-avatar",
  emits: ['delete'],
  mixins: [PlatformMixin, ProfileMixin],
  props: {
    profiles: {
      type: Array,
      default: () => {
        return []
      }
    },
    maxProfiles: {
      type: Number,
      default: 10
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('Profile', ['getType', 'getProfileLandingUrl']),
    displayProfiles() {
      return this.profiles && this.maxProfiles ? this.profiles.slice(0, this.maxProfiles) : this.profiles;
    },
    getStyle() {
      return {width:this.size+'px', height:this.size+'px'}
    },
    extra() {
      return this.profiles.length > this.maxProfiles ? this.profiles.length - this.maxProfiles : null;
    }
  },
  methods: {
    removeProfile(profile) {
      this.$emit('delete', profile);
    },
    limitText(text, length) {
      let smaller = text.substring(0, length)
      return smaller.length < text.length ? smaller+'...' : text;
    }
  }
};
</script>
<style scoped>
.not-first {
  margin-left: -10px;
}
.link-icon {
  width: 50px;
  font-size: 35px;
  clip-path: circle();
  background-color: #acacac;
      text-align: center;
}
.link-icon > i {
  position:relative;
    top: calc(50% - 10px); /* 50% - 3/4 of icon height */
}
.img-group {
  display: block;
  float: left;
}
</style>

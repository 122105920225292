<template>
  <component :is="tooltip ? 'el-tooltip' : 'div'" placement="bottom" :content="tooltip">
    <div class="profile-thumbnail__img-wrapper">
      <img :src="image" :alt="alt" @error="getErrorImage" class="profile-thumbnail__img">
    </div>
  </component>
</template>

<script>
import { ElTooltip } from 'element-plus';
import { inject } from 'vue'
export default {
  name: 'circle-image',
  components: {
    ElTooltip,
  },
  props: {
    url: {
      type: String,
      default: () => {
        //props dont have "this" in vue 3
        return inject('url', 'onerror');
      },
    },
    onerror: {
      type: String,
      default: () => {
        return '/img/diva_avatar.png'
      }
    },
    alt: {
      type: String,
      default: () => {
        return 'Avatar'
      }
    },
    tooltip: {
      type: String,
      default: () => {
        return '';
      }
    }
  },
  data() {
    return {
      error: false,
    }
  },
  computed: {
    image() {
      if (this.error || !this.url) {
        return this.onerror;
      }
      return this.url;

    },
  },
  methods: {
    getErrorImage() {
      this.error = true;
    }
  },
}
</script>

<style scoped>
.img {
  clip-path: circle();
}
</style>

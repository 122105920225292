<script>

import moment from "moment";

export default {
  methods: {
    statusStr(order) {
      return this.getTrans('messages.order_status_' + order.status);
    },
    isActive(order) {
      return order.status === 1;
    },
    progress(order) {
      let progress = 0;
      if (typeof order.price !== "undefined") {
        progress = (order.delivered_visitors / order.visitors) * 100;
      } else {
        progress = (order.spent / order.paid) * 100;
      }
      return parseInt(progress);
    },
    progressType(order) {
      const progressTypes = {
        0: 'warning',
        1: 'success',
        2: 'danger',
        3: 'muted',
        4: 'primary',
      };
      return progressTypes[order.status];
    },
    progressPercent(order) {
      return Math.floor((order.spent / order.paid) * 100) + '%';
    },
    showRenewals(order){
      return order.autorenew && order.status !== 2;
    },
    readyForRenew(order) {
      return order.autorenew && order.status === 4 && order.renewals_left > 0;
    },
    renewals(order) {
      return order.renewals_count + '/' + (this.endless(order) ? '&infin;' : order.total_renewals);
    },
    endless(order) {
      return order.total_renewals === -1;
    },
    autoRenewIn(order) {
      if (order.renewals_left > 0) {
        let next_start = moment.utc(order.completed_at).add(order.autorenew, 'minutes');
        let now = moment.utc(new Date());
        let diff_minutes = next_start.diff(now, 'minutes');
        let diff_hours = next_start.diff(now, 'hours');
        if (diff_minutes < 1) {
          return this.getTrans('messages.renews_any_moment');
        } else if(diff_minutes >= 1 && diff_minutes < 120) {
          return this.getTransChoise('messages.renews_minutes', diff_minutes)
        } else {
          return this.getTransChoise('messages.renews_hours', diff_hours);
        }
      } else {
        return false
      }
    }
  }
};
</script>

<template>
  <a :href="link" target="_blank">
    <CircleImage v-if="getType(profile)=='profile'"
      :tooltip="showTooltip ? profile.title || profile.performer.data.name : ''"
      :alt="getType(profile)=='profile' ? profile.performer.data.name + ' image': profile.title + 'image'"
      :url="getImgUrl(profile)"
      >
    </CircleImage>

    <component :is="showTooltip ? 'el-tooltip' : 'div'" v-if="getType(profile)=='link'" placement="bottom" :content="profile.title">
      <div class="profile-thumbnail__img-wrapper">
        <i class="fa fa-link profile-thumbnail__icon"></i>
      </div>
    </component>
  </a>
  <component :is="showTooltip ? 'el-tooltip' : 'div'" v-if="getType(profile)=='profile'" placement="top" :content="getTrans('messages.' + statusText)">
    <div class="profile-thumbnail__online-status" :class="statusText"></div>
  </component>
</template>

<script>

import {mapGetters} from "vuex";
import CircleImage from "@/components/Images/CircleImage"
import ProfileImg from "@/views/Profiles/Composables/ProfileImg";
import ProfileStatusMixin from "@/mixins/Profile/ProfileStatusMixin";

export default {
  components:{
    CircleImage
  },
  name: "profiles-avatar",
  mixins: [ProfileStatusMixin],
  props: {
    profile: {
      type: Object,
      required: true
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapGetters('Profile', ['getType']),
    link() {
      return process.env.VUE_APP_API_URL + 'profile/go_to_landing/' + this.profile.id;
    }
  },
  setup() {
    const { getImgUrl } = ProfileImg();
    return {
      getImgUrl
    };
  }
};
</script>

<template>
  <el-tooltip :content="getTrans('messages.pause')" v-if="status === 'active'">
    <base-button
      @click="changeStatus('pause')"
      type="warning"
      class="btn-sm active"
      :loading="saving"
    >
      <i class="fa fa-pause"></i>
    </base-button>
  </el-tooltip>
  <el-tooltip :content="getTrans('messages.activate')" v-if="status === 'paused'">
    <base-button
      @click="changeStatus('active')"
      type="success"
      class="btn-sm active"
      :loading="saving"
    >
      <i class="fa fa-play"></i>
    </base-button>
  </el-tooltip>
  <el-tooltip :content="getTrans('messages.renew')" v-if="status === 'completed'">
    <base-button
      @click="renew"
      class="btn-sm"
      type="success"
      :loading="saving"
    >
      <i class="fa fa-redo"></i>
    </base-button>
  </el-tooltip>
  <el-tooltip :content="getTrans('messages.edit')" v-if="status !== 'cancelled' && status !== 'active'">
    <base-button
      @click="edit"
      type="primary"
      class="btn-sm btn-secondary"
      :loading="saving"
    >
      <i class="fa fa-edit"></i>
    </base-button>
  </el-tooltip>
  <el-tooltip :content="getTrans('messages.cancel')" v-if="status !== 'cancelled' && status !== 'completed'">
    <base-button
      @click="changeStatus('cancel')"
      type="danger"
      class="btn-sm"
      :loading="saving"
    >
      <i class="fa fa-trash"></i>
    </base-button>
  </el-tooltip>
  <el-tooltip :content="getTrans('messages.stats')" v-if="showStats">
    <base-button
      type="info"
      @click="openStats"
      class="btn-sm"
      :loading="saving"
    >
      <i class="fa fa-chart-line"></i>
    </base-button>
  </el-tooltip>
</template>
<script>

import { mapGetters } from "vuex";

export default {
  name:'order-actions',
  props: {
    order: {
      type: Object,
      required: true
    },
    showStats: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapGetters('Order', ['statuses']),
    status() {
      return this.statuses[this.order.status];
    }
  },
  methods: {
    changeStatus(status) {
      this.saving = true;
      this.$store.dispatch('Order/changeStatus', {
        id: this.order.id,
        status: status
      }).finally(() => this.saving = false);
    },
    renew() {
      this.saving = true;
      this.$store.dispatch('Order/renew', this.order.id)
        .finally(() => this.saving = false);
    },
    openStats() {
      this.$router.push({name: 'order_stats', params: {orderId: this.order.id}});
    },
    edit() {
      this.$router.push({name: 'boost', params: {orderId: this.order.id}});
    },
  }
};
</script>

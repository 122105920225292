import hmacsha1 from "hmacsha1";

export default function profileImg() {

  const getImgUrl = (profile) => {
    if (profile.type === 2) { //Link
      return '../../../img/diva_avatar.png';
    }
    if (profile.profilePicUrl) {
      return profile.profilePicUrl;
    } else if (
      (profile.performer.data.avatar_url !== "" ||
        profile.performer.data.screenshot_url !== "") &&
      (profile.performer.data.avatar_url !== null ||
        profile.performer.data.screenshot_url !== null)
    ) {
      let url = profile.performer.data.avatar_url
        ? profile.performer.data.avatar_url
        : profile.performer.data.screenshot_url
          ? profile.performer.data.screenshot_url
          : "";
      let image_url = encodeURIComponent(
        url.replace(/^http:\/\/|^\/\/|^https:\/\/|^\/\//i, "")
      );
      let hash = hmacsha1(process.env.VUE_APP_THUMBOR_SECRET, image_url)
        .replace(/\//g, "_")
        .replace(/\+/g, "-");
      return process.env.VUE_APP_THUMBOR_SERVER + hash + "/" + image_url;
    } else {
      return '../../../img/diva_avatar.png';
    }
  };

  return {
    getImgUrl,
  };
}
